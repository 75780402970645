// 生产环境
let config = {
  baseUrl: 'http://localhost:9900', // 网关地址，一般使用内网地址
  tokenName: 'EDU_WEB_TOKEN', // token名称
  tokenExpires: 60 * 60 * 1000 * 24 // token 过期时间为1个小时
}

if (process.env.NODE_ENV === 'development') {
  // 开发环境
  config = {
    ...config,
    baseUrl: 'https://dev.52read.cn/gateway', // 开发网关地址
    url: 'dev.52read.cn' // 店铺域名，本地开发使用，生产不用配置该属性
  }
}
export default config
