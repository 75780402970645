import axios from 'axios'
import { ElMessage } from 'element-plus'
import cookie from '@/utils/cookies'
import config from '@/config/index'
import { clearSession, setSession } from '@/utils/storage'
import { useUserStore } from '@/stores/user'

class AjaxError extends Error {
  constructor (res) {
    super(res.data.msg)
    this.name = '接口异常报错:' + res.config.url
    this.response = res
  }
}

let serverUrl = ''
const writePath = ['/course/list', '/course/video', '/course/audio', '/course/graphic', '/course/live', '/course/subject', '/course/topic']

const createHttp = () => {
  const options = {
    timeout: 60000
  }
  const head = {}
  // 需要全路径才能工作
  if (process.server) {
    const nuxtApp = useNuxtApp()
    if (nuxtApp && nuxtApp.ssrContext.event && nuxtApp.ssrContext.event.req) {
      const cookieData = cookie.getInServer(nuxtApp.ssrContext.event.req)
      if (cookieData[config.tokenName]) {
        head.token = cookieData[config.tokenName]
      }
      if (cookieData.shopId) {
        head.sid = cookieData.shopId
      }
    }
    options.baseURL = config.baseUrl
  }

  if (process.client) {
    const { removeToken } = useUserStore()
    options.baseURL = '/gateway'
    if (cookie.getInClient(config.tokenName)) {
      head.token = cookie.getInClient(config.tokenName)
    } else {
      removeToken()
    }
    head.sid = cookie.getInClient('shopId')
  }
  if (!head.sid) {
    // 没有店铺ID时提示，不请求服务
    if (process.client) {
      ElMessage.error('店铺不存在')
    }
    return function () {
      return {
        get () {},
        post () {}
      }
    }
  }
  options.headers = head
  // 添加请求拦截器
  const http = axios.create(options)
  http.interceptors.request.use(function (config) {
    serverUrl = config.url
    return config
  }, function (error) {
    // 对请求错误做些什么
    if (process.client) {
      return Promise.reject(error)
    }
  })
  http.interceptors.response.use(function (response) {
    if (response.config.url.indexOf('/base/api/wx/op/bind/authorization/info') > -1) {
      return Promise.resolve(response.data)
    }
    // 校验接口银行卡
    if (response.config.url.includes('https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?')) {
      return Promise.resolve(response.data)
    }
    // 请求登录接口完成后更新token 过期时间
    if (serverUrl.indexOf('auth') !== -1 && process.client) {
      const NowTime = new Date()
      NowTime.setTime((NowTime.getTime()) + config.tokenExpires)
      cookie.setInClient({ key: config.tokenName, val: cookie.getInClient(config.tokenName), expires: NowTime })
    }

    if (response.config.data || response.config.params) {
      let d = response.config.data || response.config.params
      if (typeof d === 'string') {
        try {
          d = JSON.parse(d)
        } catch (err) {}
      }
      if (d._No_dispose) {
        return Promise.resolve(response.data)
      }
    }
    // 对响应数据做点什么
    if (response.data.code === 200) {
      return Promise.resolve(response.data.data)
    } else {
      if (response.data.code >= 300 && response.data.code <= 400 && response.data.code !== 321) {
        if (process.client) { // 客户端请求接口token 过期让他重新登录
          setSession('userInfo', '')
          clearSession()
          cookie.delInClient(config.tokenName)
          if (writePath.indexOf(window.location.pathname) === -1) {
            const _route = window.location.pathname + window.location.search
            window.location.href = '/login?redirect=' + encodeURIComponent(_route)
          }
          return Promise.reject(response.data)
        }
      }

      if (process.client) {
        // 严重接口错误上报到sentry平台上
        if (response.data.msg && (response.data.msg.indexOf('系统错误') > -1 || response.data.msg.indexOf('系统繁忙') > -1)) {
          window.captureAxiosException && window.captureAxiosException(new AjaxError(response))
        }
        try {
          const d = (response.config.data && JSON.parse(response.config.data)) || response.config.params
          if (d !== false) {
            // 过滤同时多个接口报token错误 会出现多个提示bug
            const title = localStorage.getItem('___errmsg')
            const time = localStorage.getItem('___errmsgTime')
            const newtime = (new Date()).getTime()
            if (title !== response.data.msg || (newtime - time) > 2000) {
              localStorage.setItem('___errmsg', response.data.msg)
              localStorage.setItem('___errmsgTime', newtime)
              ElMessage.error(response.data.msg)
            }
            return Promise.reject(response.data.data || response.data)
          } else {
            return Promise.resolve(response.data.data || response.data)
          }
        } catch (e) {}
      } else {
        return Promise.resolve(response.data.data || response.data)
      }
    }
  }, function (error) {
    // 对响应错误做点什么
    ElMessage.error(error.message)
    if (process.client) {
      window.captureAxiosException && window.captureAxiosException(error)
      return Promise.reject(error)
    } else {
      // console.info('request to: ' + serverUrl)
      // console.info(JSON.stringify(error))
      return Promise.resolve(error.response.data)
    }
  })
  return http
}

export default createHttp
